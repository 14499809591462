<template>
    <div>
        <template v-if="cardStatus==null">
            <div class="card">
                <div>
                    <img src="@/assets/images/photo/class_not.png">
                    <div class="f14 c-2 m-b-20">您的企业还未开通名片功能</div>
                    <el-button type="primary" size="mini" @click="$oucy.go('/enter/CardSave')">申请开通名片</el-button>
                </div>
            </div>
        </template>
        <template v-else-if="cardStatus==0">
            <div class="card">
                <div>
                    <img src="@/assets/images/photo/class_not.png">
                    <div class="f14 c-2 m-b-20">您的企业名片审核中</div>
                    <el-button type="primary" size="mini" @click="$oucy.go('/enter/CardSave')">修改申请资料</el-button>
                </div>
            </div>
        </template>
        <template v-else-if="cardStatus==2">
            <div class="card">
                <div>
                    <img src="@/assets/images/photo/class_not.png">
                    <div class="f14 c-2 m-b-20">您的企业名片驳回审核；驳回原因：{{cardCase||'无'}}</div>
                    <el-button type="primary" size="mini" @click="$oucy.go('/enter/CardSave')">重新申请</el-button>
                </div>
            </div>
        </template>
        <template v-else-if="cardStatus==1">
            <div class="middle p-40 text-left">
                <div class="c-2 f16 df-jc-s-b">
                    <span class="pointer"></span>
                    <el-button @click="$oucy.go('/enter/CardSave')" size="mini" type="primary">编辑名片</el-button>
                </div>
                <div class="df m-t-20">
                    <div><img :src="$oucy.ossUrl + detail.enterpriseAvata" class="enterpriseAvata" v-if="detail.enterpriseAvata"></div>
                    <div class=" m-l-20">
                        <div class="c-1 f24 m-t-10">{{detail.enterpriseName}}</div>
                        <div class="df c-2 m-t-25">
                            <div class="m-r-70">法定代表人<span class="m-l-10 c-1">{{detail.enterpriseLegalPerson}}</span></div>
                            <div class="m-r-70">注册资本<span class="m-l-10 c-1">{{detail.enterpriseRegCapital}}</span></div>
                            <div class="m-r-70">成立日期<span class="m-l-10 c-1">{{detail.enterpriseSetUpDate}}</span></div>
                        </div>
                    </div>
                </div>
                <div class="df-jc-s-b text-center c-1 m-t-40">
                    <div class="box1">
                        <div class="f18 bold">{{detail.cardPopularity}}</div>
                        <div class="f14 m-t-15 c-2">人气</div>
                    </div>
                    <div class="box1">
                        <div class="f18 bold">{{detail.cardLike}}</div>
                        <div class="f14 m-t-15 c-2">点赞</div>
                    </div>
                    <div class="box1">
                        <div class="f18 bold">{{detail.cardCollect}}</div>
                        <div class="f14 m-t-15 c-2">收藏</div>
                    </div>
                    <div class="box1">
                        <div class="f18 bold">{{detail.cardTransmit}}</div>
                        <div class="f14 m-t-15 c-2">转发</div>
                    </div>
                </div>
                <div class="f16 c-1 m-t-50">
                    <p v-html="detail.enterpriseIntro" class="html"></p>
                </div>
                <div class="m-t-40 df m-b-30">
                    <div><img :src="$oucy.ossUrl + detail.cardCode" class="cardCode" v-if="detail.cardCode"></div>
                    <div class="c-2 f16 m-l-15">
                        <div>企业电话<span class="m-l-10 c-1">{{detail.cardPhone}}</span></div>
                        <div class="m-t-15">企业地址<span class="m-l-10 c-1">{{detail.enterpriseAddr}}</span></div>
                        <div class="m-t-15">企业品牌<span class="m-l-10 c-1" v-for="(v,i) of brands" :key="i" :title="v.brandDesc">{{v.brandName}}</span></div>
                    </div>
                </div>
                <div v-if="detail.enterpriseImage">
                    <div class="bold f20 c-1">企业视频/相册</div>
                    <MyMedia :srcList="$oucy.sortMp4Image(detail.enterpriseImage)" class="m-t-20" :size="163"/>
                </div>
                <div class="df-jc-s-b" style="position:relative;">
                    <el-tabs v-model="activeName" @tab-click="handleClick" style="flex:1">
                        <el-tab-pane label="员工名片" name="first">
                            <el-button-group>
                                <el-button v-for="v of employeeStatus" :key="v.id" @click="SetCardStatus(v)" :type="QueryFurnitureLibrary.employeeStatus==v.id?'primary':''">{{v.text}}</el-button>
                            </el-button-group>
                        </el-tab-pane>
                        <el-tab-pane label="名片回收站" name="second"></el-tab-pane>
                    </el-tabs>
                    <div style="position: absolute;right: 0;top: 0;">
                        <el-button size="mini" type="primary" @click="$oucy.go('/enter/CardStaffSave')">添加员工名片</el-button>
                    </div>
                </div>
                <div v-if="ReturnsObject.content" class="m-t-30">
                    <!-- <div class="bold c-1 f16">员工名片</div> -->
                    <div class="m-t-20 box3s">
                        <div v-for="(v,i) in ReturnsObject.content" :key="i" class="box3 df-ai-c">
                            <div class="df-ai-c">
                                <div><img :src="$oucy.ossUrl + v.employeePic" class="employeePic" v-if="v.employeePic"></div>
                                <div class="m-l-10">
                                    <div class="c-1">{{v.employeeName}}<span class="m-l-10 c-2 f14">{{v.employeeJob}}</span>
                                        <span style="color:#F56C6C;" class="f12 m-l-10" v-if="v.employeeStatus==0">待审核</span>
                                        <span style="color:#E6A23C;" class="f12 m-l-10" v-if="v.employeeStatus==1">审核通过</span>
                                        <span style="color:#f00" class="f12 m-l-10" v-if="v.employeeStatus==2">驳回审核</span>
                                    </div>
                                    <div class="c-2 f14 m-t-10">{{v.employeePhone}}</div>
                                </div>
                            </div>
                            <div v-if="activeName=='first'">
                                <div>
                                    <el-button size="mini" icon="el-icon-edit" type="text" @click="$oucy.go('/enter/CardStaffSave?id='+v.id)">编辑资料</el-button>
                                </div>
                                <div class="m-t-10">
                                    <el-button size="mini" icon="el-icon-chat-square" type="text" @click="$oucy.go('/enter/CardStaffDetail?id='+v.id)">评论管理</el-button>
                                </div>
                                <div class="m-t-10">
                                    <el-button size="mini" icon="el-icon-delete" type="text" @click="delConfirm(v,i,'del')">删除名片</el-button>
                                </div>
                            </div>
                            <div v-else>
                                <div>
                                    <el-button size="mini" icon="el-icon-edit" type="text" @click="recoverCardEmployee(v,i)">恢复名片</el-button>
                                </div>
                                <div class="m-t-10">
                                    <el-button size="mini" icon="el-icon-delete" type="text" @click="delConfirm(v,i,'deep')">彻底删除</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="text-center c-2 p-40">没有名片</div>
            </div>
            <div class="pagination">
                <el-pagination v-if="ReturnsObject.content" background layout="sizes, prev, pager, next, jumper, ->, total, slot" :page-size="ReturnsObject.size" :total="ReturnsObject.totalElements" @size-change="sizeChange" @current-change="currentChange" @prev-click="currentChange" @next-click="currentChange">
                </el-pagination>
            </div>
        </template>
    </div>
</template>
<script>
import { cardEmployee, enterpriseinfo, ltIdentity, china, cardCategory, cardPerson, cardEnterprise, furnitureGlobalBrand } from "@/service/index.js"
import { localSet, localGet, localDel } from "@/store/store";
import oucy from "@/util/oucyUtil";

export default {
    name: "Card",
    data() {
        return {
            // 0待审核 1审核通过 2驳回审核
            employeeStatus: [
                { text: '全部', id: null },
                { text: '待审核', id: 0 },
                { text: '审核通过', id: 1 },
                { text: '驳回审核', id: 2 },
            ],
            type: 1,
            showcity: false,
            showpro: false,

            bannerHeight: 1000,
            // 企业分类
            allGlobalClassifyList1: [],
            // 个人分类
            allGlobalClassifyList0: [],
            // 请求对象            
            QueryFurnitureLibrary: {
                limit: 10,
                start: 0,
                // enterpriseAuthenticationLevel: 0, //   认证级别：0全部 1实名认证 2信守约 3品质工厂
                cardPersonSort: 0, //  排序方式：0 销量[默认], 排序1 最新, 3 收藏, 4 人气,   
                cardRegion: null, //    位置区域
                siteId: null, //    站点
                employeeStatus: null, //    站点
                cardCategory: null, //名片分类
                isShow:true,
            },
            // 返回对象
            ReturnsObject: {},
            // selectedChina4: {},
            detail: {},
            id: null,
            activeName: 'first',
            brands: [],
            // 审核状态：0待审核 1审核通过 2驳回审核
            cardStatus: null,
            cardCase: null,


        }
    },
    mounted() {
        let enterprise = localGet('enterprise')
        if (enterprise && enterprise.id) {
            this.id = enterprise.id
        } else {
            this.$oucy.replace(`/acc/enterprise`)
        }
        this.QueryFurnitureLibrary.employeeEnterprise = this.id
        this.getCardEnterpriseDetail()
        this.getList()
    },
    methods: {
        // 员工名片  名片回收站
        handleClick() {
            this.QueryFurnitureLibrary.start = 0
            this.ReturnsObject = {}
            this.getList()
        },
        // 切换状态回调
        SetCardStatus(v) {
            this.QueryFurnitureLibrary.start = 0
            this.QueryFurnitureLibrary.employeeStatus = v.id
            this.getList()
        },

        // 切换页数
        sizeChange: function(pageSize) {
            const me = this;
            me.QueryFurnitureLibrary.limit = pageSize;
            me.getList();
        },
        // 切换页码
        currentChange: function(current) {
            const me = this;
            me.QueryFurnitureLibrary.start = current - 1;
            me.getList();
        },
        // 查详情
        getCardEnterpriseDetail() {
            cardEnterprise.getCardEnterpriseDetail({ id: this.id }).then(res => {
                if (res) {
                    this.cardStatus = res.cardStatus
                    this.cardCase = res.cardCase
                    if (this.cardStatus == 1) {
                        // this.$oucy.replace('/enter/CardDetail')
                    }
                    this.detail = res || {}
                }
            })
        },
        // 查列表 
        getList() {
            if (this.activeName == 'first') {
                this.getCardEmployeeList()
            } else {
                this.getRecycleCardEmployeeList()
            }
        },
        // 查询员工
        getCardEmployeeList() {
            this.QueryFurnitureLibrary.isDelete=!true
            cardEmployee.getCardEmployeeList(this.QueryFurnitureLibrary).then(res => {
                this.ReturnsObject = res || {}
            })
        },
        // 查询回收站员工名片列表
        getRecycleCardEmployeeList() {
            this.QueryFurnitureLibrary.isDelete=true
            cardEmployee.getCardEmployeeList(this.QueryFurnitureLibrary).then(res => {
                this.ReturnsObject = res || {}
            })
        },
        // 删除员工
        delCardEmployee(v, i) {
            cardEmployee.delCardEmployee({ id: v.id }).then(res => {
                this.$message('删除成功')
                this.delContent(i)
            })
        },
        delConfirm(v, i, type) {
            this.$confirm(type == 'deep' ? `确定要彻底删除员工名片吗？彻底删除后不可恢复，请谨慎操作` : '确定要删除员工名片吗？被删除的员工名片可以在名片回收站中恢复', type == 'deep' ? '彻底删除' : '删除员工', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                type == 'deep' ? this.deepCardEmployee(v, i) : this.delCardEmployee(v, i)
            }).catch(() => {});
        },
        // 彻底删除员工名片
        deepCardEmployee(v, i) {
            cardEmployee.deepCardEmployee({ id: v.id }).then(res => {
                this.delContent(i)
            })
        },
        // 恢复员工名片
        recoverCardEmployee(v, i) {
            cardEmployee.recoverCardEmployee({ id: v.id }).then(res => {
                this.$message('恢复成功')
                this.delContent(i)
            })

        },
        // 删除
        delContent(i) {
            this.ReturnsObject.content.splice(i, 1)
            if (this.ReturnsObject.content.length == 0) {
                this.getList()
            }
        },
    }
}
</script>
<style scoped lang="less">
.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
}

.left_nav .el-button--text {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    margin: 30px 0 0 30px;
    color: #111111;
}

.left_nav .el-button--text:focus {
    color: #2090FF;
}

.el-menu {
    border-right: 0 !important;
    height: 900px;
}

.el-menu-item {
    font-size: 13px;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.el-collapse {
    border: none;
}

.el-collapse-item__header {
    border: none;
}

.el-collapse-item__wrap {
    margin-top: 20px;
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
}

.place {
    margin-bottom: 20px;
    margin-left: -170px;
}

.place>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth {
    margin-bottom: 30px;
    display: flex;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
    margin-top: 10px;
    text-align: left;
}

.top_sort .el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
}

.sort {
    margin-bottom: 30px;
    display: flex;
}

.sort>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
}

.el-radio-button--mini {
    border: none;
}

.el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
}

.el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

/deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
}

.middle ul li:nth-child(3n) {
    margin-right: 0px;
}

.middle ul li {
    width: 252px;
    height: 280px;
    background-color: #FFFFFF;
    padding: 24px;
    display: inline-block;
    /*float: left;*/
    margin: 10px;
    list-style: none;
}

.personal_card ul li {
    height: 250px;
}

.enterprise_card {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 10px;
}

.enterprise_card span {
    width: 160px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    float: left;
    display: flex;
    padding: 25px 0 0 10px;
}

.com_head {
    width: 80px;
    height: 80px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
}

.com_head img {
    margin: 15px auto;
    width: 100%;
    height: 100%;
}

.enterprise_card .info {
    width: 80px;
    /*height: 43px;*/
    background: #F8F8F9;
    border-radius: 2px;
    /*margin-top: 30px;*/
    margin-left: 6px;
}

.enterprise_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 26px;
    /*margin-top: -20px;*/
}

.enterprise_card .info p+p {
    /*padding-top: 10px;*/
}

.personal_card .info {
    width: 57px;

    background: #F8F8F9;
    border-radius: 2px;

}

.personal_card .info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #888888;
    line-height: 20px;
    text-align: center;
    /*margin-top: -20px;*/
}

.staff_info {
    width: 88px;
}

.staff_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: center;
}

.contact {
    width: 233px;
    /*height: 50px;*/
    background-color: rgb(243, 249, 255);
    margin: 6px auto;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.contact .el-button--text {
    width: 70px;
    border: 1px rgb(32, 144, 255) solid;
    float: right;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.pagination {
    margin: 30px auto 120px;
}

/*/deep/ .btn-next {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}

/deep/ .btn-prev {
    width: 128px;
    height: 46px !important;
    background: #FFFFFF !important;
}

/deep/ .el-pager {
    position: relative;
    top: 9px;
}

/deep/ .number {
    background: #FFFFFF !important;
}

/deep/ .active {
    background: #2090ff !important;
}*/

.bus_recomm {
    width: 100%;
}

.left_nav {
    margin-top: 20px;
}

.left_nav .el-menu {
    /*margin-left:20px;*/
    z-index: 1000;
}

.el-menu {
    border-right: 0 !important;
    height: 900px;
}

.el-menu-item {
    font-size: 13px;
}

.nav img {
    margin-right: 8px;
}

.nav:hover .icon1 {
    display: none;
}

.nav:hover .icon2 {
    display: inline-block;
}

.icon2 {
    display: none;
}

.bold_span {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    /*margin: 30px 0 0 30px;*/
}

.top_sort {
    margin-top: 20px;
    margin-left: 10px;
    background-color: white;
    padding: 30px 0 5px 20px;
}

.place .el-button--text {
    padding: 0 10px 0 10px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #111111;
    margin-bottom: 10px;
}

.place {
    text-align: left;
    margin-bottom: 20px;
    /*margin-left: -170px;*/
    padding-left: 10px;
}

.place>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 40px;
}

.auth {
    margin-bottom: 30px;
    display: flex;
}

.auth>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.auth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
    /*margin-top: 10px;*/
    text-align: left;
}

.el-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

.sort /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    margin-left: 10px;
}

.sort {
    margin-bottom: 30px;
    display: flex;
}

.sort>span {
    width: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #111111;
    margin-right: 30px;
}

.el-radio-button--text:hover {
    color: rgb(32, 144, 255) !important;
}

.el-radio-button--mini {
    border: none;
}

.el-radio-button--mini:hover {
    background-color: rgb(32, 144, 255) !important;
}

.el-radio-button--text:focus {
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #2090FF;
}

/deep/ .el-collapse-item__wrap {
    margin-top: 20px;
    text-align: center;
}



.com_head {
    width: 80px;
    height: 80px;
    border: 1px rgb(243, 243, 243) solid;
    float: left;
}

.com_head img {
    width: 73px;
    height: 47px;
    margin-top: 15px;
}




.middle_card_star {
    float: left;
    display: flex;
    margin-left: 10px;
}

.info {
    width: 70px;
    /*height: 43px;*/
    background-color: rgb(248, 248, 249);
    padding: 2px;
    margin: 15px auto 0px;
}

.info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 6px;
}

.goods_info p {
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
    line-height: 16px;
    text-align: left;
    margin-left: 7px;
}

.pagination {
    margin-top: 50px;
    line-height: 150px;
}


.middle ul li:nth-child(3n) {
    margin-right: 0px;
}



.items {
    margin-left: 10px;
}

.item {
    padding: 20px;
    background: #fff;
    margin-top: 10px;
    display: flex;
}

.logo {
    width: 168px;
    height: 168px;
    background: #FDFDFD;
    border: 1px solid #F3F3F3;
    border-radius: 1px;
}

.dot {
    padding-left: 30px;
    flex: 1;
}

.itemTop {
    align-items: flex-start;
}

.icon3 {}

.icon4 {
    margin-right: 5px;
}

.cover {
    width: 80px;
    margin-left: 10px;
}

.myauth /deep/ .el-radio-button__inner {
    border: none;
    border-radius: 3px;
    background: #F6F7FB;
    /*margin-left: 10px;*/
    /*margin-top: 10px;*/
    text-align: left;
}

.mybutton {
    margin: 0 10px 10px 0;
    background: #F6F7FB;
    border: none;
    color: #606266;
    padding: 6px 12px;
    border-radius: 4px;
}

.text-left {
    text-align: left;

}

.myauth {
    display: flex;
    text-align: left;
}

.myauth .myauthLift {
    font-weight: bold;
    display: block;
    width: 90px;
}

.myauth .text-left {
    flex: 1;
}

.selectedChina {
    color: #fff;
    background-color: rgb(32, 144, 255);
}

.el-radio-group {
    text-align: left;
}

.middle {
    background: #fff;
    // margin-top: 20px;
    // margin-left: 20px;
}

.enterpriseAvata {
    width: 90px;
    height: 90px;
}

.box1 {
    margin-right: 20px;
    padding: 20px;
    flex: 1;
    background: #F8F8F9;
}

.cardCode {
    width: 120px;
    height: 120px;
}

.box2s {
    display: flex;
    flex-wrap: wrap;
}

.box2 {
    border-radius: 4px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px;
    box-sizing: border-box;
    background: #FAFAFA;
    background-color: #ffffff;
    padding-bottom: 15px;
    border: 1px solid #EAEAEA;
    text-align: center;
    width: 218px;

    &:hover {

        box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.08);
        background: #FFFFFF;
    }

    .box img {
        width: 100%;
        // height: 163px;
    }
}

.box3s {
    display: flex;
    flex-wrap: wrap;
}

.box3 {
    display: flex;
    justify-content: space-between;
    border: 1px solid #EAEAEA;
    width: 410px;
    padding: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    cursor: pointer;
}

.employeePic {
    width: 80px;
    height: 80px;
}

.html /deep/ img {
    max-width: 100%;
}

.card {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    min-height: 700px;
    text-align: center;
}
</style>